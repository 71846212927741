import React, { useEffect, useState } from 'react';

// Modules
import { message } from 'antd';


// Components
import FormView from './FormView';

const FormContainer = (props) => {
    
    props = props.parent_props;

    const [ idToUpdate, setIdToUpdate ] = useState(null);
    useEffect(() => {

        /**
         * Get data to update.
         */
        async function getDataToUpdate(id) {
            // Call API.
            let apiResponse = await fetch(`${process.env.REACT_APP_API_URL}/menu-options/${id}`, 
            { 
                headers: {
                    'access_token': sessionStorage.getItem('access_token') || localStorage.getItem('access_token'),
                },
                method: 'GET',
            });
            apiResponse = await apiResponse.json();
    
            // Check if response was successfuly
            if(apiResponse.code === 200){
                
                setForm({
                    name: apiResponse.data['name'],
                })
                
            } else {
                
                message.error(apiResponse.message);
                
            }
        }

        /**
         * Check if update or create form
         */
        if(props.location.state){
            setIdToUpdate(props.location.state.id)
            getDataToUpdate(props.location.state.id);
        }
    }, [props.location.state])

    /**
     * Set form.
     */
    const [ form, setForm ] = useState({ name: '', type: '' });

    /**
     * Save.
     */
    const [ loadingSaveButton, setLoadingSaveButton ] = useState(false);
    const save = async () => {

        setLoadingSaveButton(true);

        // Method
        let method = idToUpdate ? 'PUT' : 'POST';
        let endpoint = idToUpdate ? `${process.env.REACT_APP_API_URL}/menu-options/${idToUpdate}` : `${process.env.REACT_APP_API_URL}/menu-options`;
        
        // Call API.
        let apiResponse = await fetch(endpoint, 
        { 
            headers: {
                'access_token': sessionStorage.getItem('access_token') || localStorage.getItem('access_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: method,
            body: JSON.stringify(form)
        });
        apiResponse = await apiResponse.json();

        // Check if response was successfuly
        if(apiResponse.code === 200){

            message.success(
                idToUpdate ?
                    'Registro atualizado com sucesso' :
                    'Registro criado com sucesso'
            );
            setLoadingSaveButton(false);
            props.history.push('/home/menu-options');
            
        } else {
            
            setLoadingSaveButton(false);
            message.error(apiResponse.message);
            
        }
    }

    return(

        <FormView
            idToUpdate={idToUpdate}

            form={form}
            setForm={form => setForm({ ...form })}

            save={() => save()}
            loadingSaveButton={loadingSaveButton}
        />

    )

}

export default FormContainer;