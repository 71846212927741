import React from 'react';

// Modules

// Components
import PrivacyPolicyView from './PrivacyPolicyView';

const PrivacyPolicyContainer = (props) => {
    return (

        <PrivacyPolicyView
        />

    )

};

export default PrivacyPolicyContainer;